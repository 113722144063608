
// src/components/index.jsx
import React from 'react';
import '../style.css';
import '../styleguide.css';
import logo from '../asset-1-2.svg'; // Adjust the path based on your project structure

export const Home = () => {
  return (
    <div className="home">
      <div className="navbar">
        <img src={logo} alt="Logo" className="logo" />
        <ul className="nav-links">
          <li><a href="#how-it-works">How it Works</a></li>
          <li><a href="#benefits">Benefits</a></li>
          <li><a href="#categories">Categories</a></li>
          <li><a href="#testimonials">Testimonials</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </div>
      <div className="div">
        <div id="how-it-works" className="how-it-works">
          <div className="heading">
            <p className="hero">
              <span className="text-wrapper">How it </span>
              <span className="span">works</span>
            </p>
            <p className="headline">
              Save And Earn Money With The Most Earth-friendly Form Of Style. Guaranteed Freshly Dry-cleaned.
            </p>
          </div>
          <div className="steps">
            <div className="overlap">
              <div className="div-2">
                <div className="div-wrapper">
                  <div className="person-alfred-skin" />
                </div>
                <div className="texts">
                  <div className="hero-2">List Items</div>
                  <p className="p">List Hundreds Of Books, Tools, Gadgets, and Game Appliances</p>
                </div>
              </div>
              <img className="link" alt="Link" src="link-2.svg" />
            </div>
            <div className="overlap-group">
              <div className="div-2">
                <div className="sell-them-image">
                  <div className="image-wrapper">
                    <img className="image" alt="Image" src="image-1.png" />
                  </div>
                </div>
                <div className="texts">
                  <div className="hero-2">Borrow Them</div>
                  <p className="p">Borrow Items From People Of Nearby</p>
                </div>
              </div>
              <div className="get-paid">
                <div className="div-wrapper">
                  <div className="person-ed-skin-tone">
                    <img className="element" alt="Element" src="3-29.png" />
                  </div>
                </div>
                <div className="texts">
                  <div className="hero-2">Get Paid</div>
                  <p className="p">Get It Delivered Or Pick It Up At A Nearby Wardrobe Hub</p>
                </div>
              </div>
              <img className="img" alt="Link" src="link.svg" />
            </div>
          </div>
        </div>
        <div id="benefits" className="group">
          <div className="hero-3">Benefits</div>
          <p className="headline-2">Why Use This Site As Compared To Others</p>
          <div className="group-wrapper">
            <div className="group-2">
              <p className="ensuring-a-smooth">Ensuring A Smooth And Secure Borrowing Experience</p>
              <p className="this-website-offers">
                This Website Offers A Wide Selection Of Items To Borrow, Saving You Money And Reducing Waste By Reusing Resources. It Also Features A User-friendly Interface And A Trusted Community, Ensuring A Smooth And Secure Borrowing Experience.
              </p>
              <div className="button-login">
                <div className="button-medium">SEE MORE</div>
                <img className="icon-feather-icon" alt="Icon feather icon" src="feather-icon.svg" />
              </div>
            </div>
          </div>
          <div className="overlap-2">
            <div className="see-more-wrapper">
              <div className="see-more">
                <div className="group-3">
                  <p className="save-money-and">
                    Save Money And Reduces Waste, Promoting Sustainability And Financial Efficiency
                  </p>
                  <p className="borrowing-items">
                    Borrowing Items Helps Save Money And Reduces Waste, Promoting Sustainability And Financial Efficiency. It Also Fosters Community Connections By Encouraging Resource Sharing And Collaboration.
                  </p>
                  <div className="button-login-2">
                    <div className="button-medium">SEE MORE</div>
                    <img className="icon-feather-icon" alt="Icon feather icon" src="feather-icon.svg" />
                  </div>
                </div>
              </div>
            </div>
            <img className="photo" alt="Photo" src="photo-1615872329955-c28d0bf453fd-2.png" />
          </div>
          <img className="photo-2" alt="Photo" src="photo-1615872329955-c28d0bf453fd-1.png" />
        </div>
        <div id="categories" className="our-categorys">
          <p className="title">
            <span className="text-wrapper-2">Browse our </span>
            <span className="text-wrapper-3">categories</span>
          </p>
          <img className="vector" alt="Vector" src="image.svg" />
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <p className="desc">
                Borrowing books provides access to a wide range of literature without the cost of purchasing, fostering a love for reading.
              </p>
              <div className="books">Books</div>
              <img className="noto-books" alt="Noto books" src="noto-books.svg" />
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap-3">
              <p className="desc-2">
                Borrowing tools allows you to tackle home projects without investing in expensive equipment, saving both money and space.
              </p>
              <div className="text-wrapper-4">Tools</div>
              <img className="vaadin-tools" alt="Vaadin tools" src="vaadin-tools.svg" />
            </div>
          </div>
          <div className="group-4">
            <div className="overlap-3">
              <p className="desc-3">
                Borrowing gadgets enables you to try out the latest technology without the commitment of purchasing.
              </p>
              <div className="text-wrapper-4">Game Appliances</div>
              <img className="mingcute-game-fill" alt="Mingcute game fill" src="mingcute-game-2-fill.svg" />
            </div>
          </div>
        </div>
        <button className="button">
          <button className="button-medium-2">VIEW MORE</button>
          <img className="icon-feather-icon" alt="Icon feather icon" src="feather-icon.svg" />
        </button>
        <div id="recommended-books" className="group-5">
          <div className="recommended-book">
            <div className="frame">
              <img className="rectangle" alt="Rectangle" src="rectangle-12.png" />
              <div className="frame-2">
                <div className="text-wrapper-5">Don’t Make Me think</div>
                <div className="text-wrapper-6">Steve Krug, 2000</div>
                <p className="element-2">
                  <span className="text-wrapper-7">4.5</span>
                  <span className="text-wrapper-8">/5</span>
                </p>
              </div>
            </div>
            <div className="frame">
              <img className="image-2" alt="Image" src="image-2-2.png" />
              <div className="frame-2">
                <div className="text-wrapper-5">Tools Screw, Plaas</div>
                <div className="text-wrapper-6">Don Norman, 1988</div>
                <p className="element-2">
                  <span className="text-wrapper-7">4.5</span>
                  <span className="text-wrapper-8">/5</span>
                </p>
              </div>
            </div>
            <div className="frame">
              <img className="rectangle-2" alt="Rectangle" src="rectangle-12-2.png" />
              <div className="frame-2">
                <p className="text-wrapper-5">Sprint : How to solve...</p>
                <div className="text-wrapper-6">Jake Knapp, 2000</div>
                <p className="element-2">
                  <span className="text-wrapper-7">4.5</span>
                  <span className="text-wrapper-8">/5</span>
                </p>
              </div>
            </div>
            <div className="frame">
              <img className="image-3" alt="Image" src="image-3-2.png" />
              <div className="frame-2">
                <div className="text-wrapper-5">Tools Of Car Mechanic</div>
                <div className="text-wrapper-6">Jeff Gothelf, 2016</div>
                <p className="element-2">
                  <span className="text-wrapper-7">4.5</span>
                  <span className="text-wrapper-8">/5</span>
                </p>
              </div>
            </div>
            <div className="frame">
              <img className="image-4" alt="Image" src="image-6.png" />
              <div className="frame-2">
                <div className="text-wrapper-5">Game Appliances</div>
                <div className="text-wrapper-6">Steve Krug, 2000</div>
                <p className="element-2">
                  <span className="text-wrapper-7">4.5</span>
                  <span className="text-wrapper-8">/5</span>
                </p>
              </div>
            </div>
          </div>
          <div className="recommended-book-2">
            <div className="frame">
              <img className="image-5" alt="Image" src="image-4.png" />
              <div className="frame-2">
                <div className="text-wrapper-5">Washing Tools</div>
                <div className="text-wrapper-6">Steve Krug, 2000</div>
                <p className="element-2">
                  <span className="text-wrapper-7">4.5</span>
                  <span className="text-wrapper-8">/5</span>
                </p>
              </div>
            </div>
            <div className="frame">
              <img className="image-6" alt="Image" src="image-7.png" />
              <div className="frame-2">
                <div className="text-wrapper-5">Game Appliances</div>
                <div className="text-wrapper-6">Don Norman, 1988</div>
                <p className="element-2">
                  <span className="text-wrapper-7">4.5</span>
                  <span className="text-wrapper-8">/5</span>
                </p>
              </div>
            </div>
            <div className="frame">
              <img className="image-7" alt="Image" src="image-5.png" />
              <div className="frame-2">
                <p className="text-wrapper-5">Sprint : How to solve...</p>
                <div className="text-wrapper-6">Jake Knapp, 2000</div>
                <p className="element-2">
                  <span className="text-wrapper-7">4.5</span>
                  <span className="text-wrapper-8">/5</span>
                </p>
              </div>
            </div>
            <div className="frame">
              <img className="rectangle-3" alt="Rectangle" src="image.png" />
              <div className="frame-2">
                <p className="text-wrapper-5">Learn UX : Design Gr...</p>
                <div className="text-wrapper-6">Jeff Gothelf, 2016</div>
                <p className="element-2">
                  <span className="text-wrapper-7">4.5</span>
                  <span className="text-wrapper-8">/5</span>
                </p>
              </div>
            </div>
            <div className="frame">
              <img className="image-8" alt="Image" src="image-8-2.png" />
              <div className="frame-2">
                <div className="text-wrapper-5">The Road to React</div>
                <div className="text-wrapper-6">Steve Krug, 2000</div>
                <p className="element-2">
                  <span className="text-wrapper-7">4.5</span>
                  <span className="text-wrapper-8">/5</span>
                </p>
              </div>
            </div>
          </div>
          <p className="hero-4">
            <span className="text-wrapper">featured </span>
            <span className="span">items</span>
          </p>
          <div className="button-login-3">
            <div className="button-medium">SHOW ALL</div>
            <img className="icon-feather-icon" alt="Icon feather icon" src="feather-icon.svg" />
          </div>
        </div>
        <footer className="footer">
          <div className="overlap-4">
            <div className="rectangle-4" />
            <div className="logo" />
            <div className="group-6">
              <div className="catalog-delivery-pay">Explore</div>
              <div className="catalog-delivery-pay-2">Become A Lender</div>
              <div className="group-7">
                <div className="text-wrapper-9"></div>
                <div className="text-wrapper-10"></div>
                <div className="text-wrapper-11"></div>
                <div className="text-wrapper-12"></div>
              </div>
            </div>
            <div className="rectangle-5" />
            <p className="we-will-never-spam-t">© 2023 bow bow barter. All rights reserved.</p>
            <div className="we-will-never-spam-t-2">Terms of service</div>
            <div className="we-will-never-spam-t-3">Help & Support</div>
            <div className="we-will-never-spam-t-4">Privacy policy</div>
          </div>
        </footer>
        <div id="testimonials" className="group-8">
          <div className="overlap-5">
            <div className="testimonial">
              <div className="testimonial-card">
                <img className="vector-2" alt="Vector" src="vector.svg" />
                <p className="text-wrapper-13">
                  Pellentesque eu nibh eget mauris congue mattis mattis nec tellus. Phasellus imperdiet elit eu magna dictum, bibendum cursus velit sodales. Donec sed neque eget
                </p>
                <div className="client">
                  <div className="reviewer">
                    <img className="image-9" alt="Image" src="image-2.png" />
                    <div className="info">
                      <div className="text-wrapper-14">Robert Fox</div>
                      <div className="text-wrapper-15">Customer</div>
                    </div>
                  </div>
                  <img className="rating" alt="Rating" src="rating.svg" />
                </div>
              </div>
              <div className="testimonial-card">
                <img className="vector-2" alt="Vector" src="vector.svg" />
                <p className="text-wrapper-13">
                  Pellentesque eu nibh eget mauris congue mattis mattis nec tellus. Phasellus imperdiet elit eu magna dictum, bibendum cursus velit sodales. Donec sed neque eget
                </p>
                <div className="client">
                  <div className="reviewer">
                    <img className="image-9" alt="Image" src="image-3.png" />
                    <div className="info-2">
                      <div className="text-wrapper-14">Dianne Russell</div>
                      <div className="text-wrapper-15">Customer</div>
                    </div>
                  </div>
                  <img className="rating" alt="Rating" src="rating.svg" />
                </div>
              </div>
              <div className="testimonial-card">
                <img className="vector-2" alt="Vector" src="vector.svg" />
                <p className="text-wrapper-13">
                  Pellentesque eu nibh eget mauris congue mattis mattis nec tellus. Phasellus imperdiet elit eu magna dictum, bibendum cursus velit sodales. Donec sed neque eget
                </p>
                <div className="client">
                  <div className="reviewer">
                    <img className="image-9" alt="Image" src="image-8.png" />
                    <div className="info-3">
                      <div className="text-wrapper-14">Eleanor Pena</div>
                      <div className="text-wrapper-15">Customer</div>
                    </div>
                  </div>
                  <img className="rating" alt="Rating" src="rating.svg" />
                </div>
              </div>
            </div>
            <div className="arrow">
              <div className="aoorw">
                <img className="group-9" alt="Group" src="group-2.png" />
              </div>
              <div className="img-wrapper">
                <img className="group-10" alt="Group" src="group.png" />
              </div>
            </div>
            <div className="hero-5">Testimonials</div>
          </div>
        </div>
        <div id="contact" className="group-11">
          <div className="group-12">
            <div className="group-13">
              <div className="group-14">
                <div className="catalog-delivery-pay-3">Explore</div>
                <div className="catalog-delivery-pay-4">Become A Lender</div>
                <div className="btn-outline">
                  <div className="group-15">
                    <div className="catalog-delivery-pay-wrapper">
                      <div className="catalog-delivery-pay-5">Sign In</div>
                    </div>
                  </div>
                </div>
                <button className="btn">
                  <div className="group-16">
                    <div className="group-17">
                      <div className="sign-up-wrapper">
                        <div className="sign-up">Sign Up</div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <div className="lang">
                <div className="overlap-6">
                  <img className="translate" alt="Translate" src="translate.svg" />
                  <div className="text-wrapper-16">Lang</div>
                  <img className="polygon" alt="Polygon" src="polygon-1.svg" />
                </div>
              </div>
              <div className="quick-search-bar">
                <div className="overlap-7">
                  <div className="group-18">
                    <div className="overlap-group-3">
                      <div className="text-wrapper-17">All</div>
                      <img className="polygon-2" alt="Polygon" src="polygon-1.svg" />
                    </div>
                  </div>
                  <img className="barcode-scanner" alt="Barcode scanner" src="barcode-scanner.svg" />
                  <img className="search" alt="Search" src="search.svg" />
                  <img className="line" alt="Line" src="line-1.svg" />
                  <div className="text-wrapper-18">Search</div>
                </div>
              </div>
            </div>
          </div>
          <img className="asset" alt="Asset" src="asset-1-2.svg" />
        </div>
        <div className="group-19">
          <div className="group-20">
            <div className="start-borrowing-wrapper">
              <div className="start-borrowing">Start Borrowing</div>
            </div>
            <div className="become-a-lender-wrapper">
              <div className="become-a-lender">Become A Lender</div>
            </div>
            <div className="group-21">
              <div className="search-2">
                <div className="overlap-8">
                  <p className="text-wrapper-19">Search for items to borrow</p>
                  <div className="group-22">
                    <div className="search-wrapper">
                      <div className="search-3">SEARCH</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-23">
                <img className="borrow-books-from" alt="Borrow books from" src="borrow-books-from-people-nearby.svg" />
                <p className="join-a-community">Join A Community Where Every Item Has A Story</p>
              </div>
            </div>
          </div>
          <img className="image-10" alt="Image" src="image-13.png" />
        </div>
      </div>
    </div>
  );
};
